export const arcade = {
  name: 'arcade',
  path: '/arcade',
  component: () => import('@/views_mobile/member/arcade'),
  meta: {
    layout: 'mobile-default',
    text: '라이브카지노',
    gnb: false
  }
}
