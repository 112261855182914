<template>
  <footer>
    <div class="foot_menu">
      <ul>
        <li>
          <button @click="goPageByName('charge')">
            <img src="@/assets_mobile/img/core/main/deposit.png" alt="" />
            <span>{{$t('front.common.deposit')}}</span>
          </button>
        </li>
        <li>
          <button @click="goPageByName('exchange')">
            <img src="@/assets_mobile/img/core/main/withdrawal.png" alt="" />
            <span>{{$t('front.common.withdrawal')}}</span>
          </button>
        </li>
        <li>
          <button href="#wrap" class="home" @click="goPageByName('mobileMain')">
            <img src="@/assets_mobile/img/core/main/goTop.png" alt="" />
          </button>
        </li>
        <li>
          <!-- todo: 무슨 링크를 걸지 정해서 변경 필요 현재는 문의목록으로 걸려있음 -->
          <button @click="goPageByName('qnaList')">
            <img src="@/assets_mobile/img/core/main/contact.png" alt="" />
            <span>{{$t('front.common.qnaShort')}}</span>
          </button>
        </li>
        <li>
          <!-- todo: 무슨 링크를 걸지 정해서 변경 필요 현재는 공지사항으로 걸려있음 -->
          <button @click="goPageByName('noticeList')">
            <img src="@/assets_mobile/img/core/main/speaker.png" alt="" />
            <span>{{$t('front.common.notices')}}</span>
          </button>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer'
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
