import httpClient from '@/libs/http-client'

const baseUrl = '/api/partner'
// 파트너 리스트
export function partnerList (data) {
  const url = baseUrl + '/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function partnerStatusList (data) {
  const url = baseUrl + '/status-list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerListRateChange (data) {
  const url = '/api/admin/retail/listUpdateRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function getMaxRateByRecommendId(data) {
  const url = '/api/admin/partnerRates';
  return httpClient.post(url, data).then(response => {
    return response.data;
  });
}
export function partnerCashList (data) {
  const url = baseUrl + '/cash/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerBetList (data) {
  const url = baseUrl + '/bet/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerCreate (data) {
  const url = baseUrl + '/create'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function partnerReport (data) {
  const url = baseUrl + '/report'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function partnerReportDaily (data) {
  const url = baseUrl + '/report/daily'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function handleLowerCash (data, action) {
  const url = `/api/cash/cash${action}`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function siteRate (data) {
  const url = '/api/setting/siteRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function siteRateSave (data) {
  const url = '/api/setting/siteRateSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function partnerMemIds(data) {
  const url = '/api/partner/partnerMemIds';
  return httpClient.post(url, data).then(response => {
    return response.data;
  });
}export function partnerLevels(data) {
  const url = '/api/partner/partnerLevels';
  return httpClient.post(url, data).then(response => {
    return response.data;
  });
}
export function partnerPassCheck (data) {
  const url = '/api/partner/password/check'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function registPartnerPassword (data) {
  const url = '/api/partner/password'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function updatePartnerPassword (data) {
  const url = '/api/partner/password/update'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function getPointList (data) {
  const url = '/api/partner/pointList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
