<template>
  <div id="left_section" class="big">
    <a href="/">
      <div class="left_logo"></div>
    </a>
    <div class="left_menu">
      <ul>
        <li class='li_casino'>
          <RouterLink class="btn-neon btn-neon--2" to="/">
            <i class='no_hover'>
              <img src="@/assets/img/icon/icon_home.svg" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/icon_home.svg" alt="" />
            </i>
            <span>{{ $t('front.common.home') }}</span>
          </RouterLink>
        </li>
        <li class='li_casino'>
          <RouterLink class="btn-neon btn-neon--2" to="/casino">
            <i class='no_hover'>
              <img src="@/assets/img/icon/casino_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/casino_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.gameCategory.casino') }}</span>
          </RouterLink>
        </li>
        <li class='li_slot'>
          <RouterLink class="btn-neon btn-neon--2" to="/slot">
            <i class='no_hover'>
              <img src="@/assets/img/icon/slot_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/slot_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.gameCategory.slot') }}</span>
          </RouterLink>
        </li>
        <li class='li_arcade'>
          <RouterLink class="btn-neon btn-neon--2" to="/arcade">
            <i class='no_hover'>
              <img src="@/assets/img/icon/arcade_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/arcade_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.gameCategory.arcade') }}</span>
          </RouterLink>
        </li>
        <li class='li_fishing'>
          <RouterLink class="btn-neon btn-neon--2" to="/fishing">
            <i class='no_hover'>
              <img src="@/assets/img/icon/fishing_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/fishing_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.gameCategory.fishing') }}</span>
          </RouterLink>
        </li>
      </ul>
    </div>

    <div class="left_util">
      <ul>
        <li>
          <RouterLink class="btn-neon btn-neon--2" to="/betlist?mode=betting">
            <i class='no_hover'>
              <img src="@/assets/img/icon/history_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/history_active.png" alt="" />
            </i>
            <span>{{ $t('front.common.betHistory') }}</span>
          </routerLink>
        </li>
        <li>
          <routerLink class="btn-neon btn-neon--2" :to="{ name: 'qnaList' }">
            <i class='no_hover'>
              <img src="@/assets/img/icon/edit_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/edit_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.qna') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2" to="/messagelist">
            <i class='no_hover'>
              <img src="@/assets/img/icon/message_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/message_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.notes') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2" to="/noticelist">
            <i class='no_hover'>
              <img src="@/assets/img/icon/notification_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/notification_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.notice') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2" to="/eventlist">
            <i class='no_hover'>
              <img src="@/assets/img/icon/event_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/event_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.event') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2" to="/point">
            <i class='no_hover'>
              <img src="@/assets/img/icon/point_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/point_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.point') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2" to="/mypage">
            <i class='no_hover'>
              <img src="@/assets/img/icon/mypage_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/mypage_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.mypage') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2 btn-neon--bg-green" to="/cash/deposit">
            <i class='no_hover'>
              <img src="@/assets/img/icon/deposit_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/deposit_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.MakeAdeposit') }}</span>
          </routerLink>
        </li>
        <li>
          <RouterLink class="btn-neon btn-neon--2 btn-neon--bg-red" to="/cash/withdraw">
            <i class='no_hover'>
              <img src="@/assets/img/icon/withdraw_active.png" alt="" />
            </i>
            <i class='hover'>
              <img src="@/assets/img/icon/withdraw_active.png?v=1" alt="" />
            </i>
            <span>{{ $t('front.common.makeAwithdraw') }}</span>
          </routerLink>
        </li>
        <template v-if="siteOption">
          <template v-if="userData">
            <li v-if="siteOption.recommendYn === 'Y' && userData.recommendYn === 'Y'">
              <a class="btn-neon btn-neon--2" @click="partnerConfirmOpen">
                <i>
                  <img src="@/assets/img/icon/edit_active.png" alt="" />
                </i>
                <span>{{ $t('front.mypage.exclusive') }}</span>
              </a>
            </li>
          </template>
          <li v-if="siteOption.sns1">
            <a :href="siteOption.sns1Link ? siteOption.sns1Link : '#'" :class="`sns-border-${siteOption.sns1}`">
              <img :src="loadSnsIcon(siteOption.sns1)" :alt="`${siteOption.sns1}: ${siteOption.sns1Id}`">
              <span>
                <template v-if="!siteOption.sns1Id">
                  {{ $t(`front.common.${siteOption.sns1}`) }}
                </template>
                <template v-else>
                  {{ siteOption.sns1Id }}
                </template>
              </span>
            </a>
          </li>
          <li v-if="siteOption.sns2">
            <a :href="siteOption.sns2Link ? siteOption.sns2Link : '#'" :class="`sns-border-${siteOption.sns2}`">
              <img :src="loadSnsIcon(siteOption.sns2)" :alt="`${siteOption.sns2}: ${siteOption.sns2Id}`">
              <span>
                <template v-if="!siteOption.sns2Id">
                  {{ $t(`front.common.${siteOption.sns2}`) }}
                </template>
                <template v-else>
                  {{ siteOption.sns2Id }}
                </template>
              </span>
            </a>
          </li>
          <li v-if="siteOption.sns3">
            <a :href="siteOption.sns3Link ? siteOption.sns3Link : '#'" :class="`sns-border-${siteOption.sns3}`">
              <img :src="loadSnsIcon(siteOption.sns3)" :alt="`${siteOption.sns3}: ${siteOption.sns3Id}`">
              <span>
                <template v-if="!siteOption.sns3Id">
                  {{ $t(`front.common.${siteOption.sns3}`) }}
                </template>
                <template v-else>
                  {{ siteOption.sns3Id }}
                </template>
              </span>
            </a>
          </li>
        </template>
      </ul>
    </div>

  </div>
  <PartnerGate v-if="partnerConfirm" :isNew="isNew" @closeModal="closePartnerConfirm" @onConfirm="registPartnerPw" />
</template>

<script>
import PartnerGate from '@/components/member/PartnerGate.vue'
import { mapState } from 'vuex'
import store from '@/store'
import { partnerPassCheck } from '@/api/partner'

export default {
  name: 'Navigation',
  components: { PartnerGate },
  data() {
    return {
      snsList: [],
      partnerConfirm: false,
      isNew: false
    }
  },
  computed: {
    ...mapState([
      'siteOption',
      'userData'
    ])
  },
  methods: {
    async partnerConfirmOpen() {
      try {
        const result = await partnerPassCheck()
        const { resultCode, resultMessage, data } = result
        if (resultCode === '200') {
          const isNew = data.result
          if (isNew === 'NEW') {
            this.isNew = true
          } else {
            this.isNew = false
          }
          this.partnerConfirm = true
        } else {
          alert(this.$t('partner.gateConfirm.pwCheckFail'))
          console.log(resultMessage)
        }
      } catch (e) {
        alert(this.$t('partner.gateConfirm.pwCheckFail'))
        console.log(e)
      }
    },
    closePartnerConfirm(value) {
      this.partnerConfirm = false
    },
    async openGameWelcome(value) {
      this.emitter.emit('Loading', true)
      await store.dispatch('storeGameComponent', value)
      this.emitter.emit('Loading', false)
    },
    loadSnsIcon(name) {
      try {
        return require(`@/assets/img/icon/${name}.png`)
      } catch (e) {
        console.log(e)
        return ''
      }
    }
  }
}
</script>

<!-- <style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/navigation.css"></style>
<style scoped src="@/styles/header.css"></style> -->
