export const fishing = {
  name: 'fishing',
  path: '/fishing',
  component: () => import('@/views_mobile/member/fishing'),
  meta: {
    layout: 'mobile-default',
    text: '라이브카지노',
    gnb: false
  }
}
