export const partner = {
  name: 'partner',
  path: '/partner',
  component: () => import('@/views_partner'),
  redirect: '/partner/mypage/rateInfo',
  meta: {
    layout: 'partner'
  },
  children: [
    {
      name: 'partner_mypage',
      path: '/partner/mypage',
      component: () => import('@/views_partner/mypage'),
      meta: {
        text: 'partner.menu.lower.title'
      },
      children: [
        {
          name: 'partner_mypage_rateInfo',
          path: '/partner/mypage/rateInfo',
          redirect: { name: 'partner_mypage_rateInfo_cs' },
          component: () => import('@/views_partner/mypage/pages/rateInfo'),
          meta: {
            text: 'partner.menu.lower.list.title'
          },
          children: [
            {
              name: 'partner_mypage_rateInfo_cs',
              path: '/partner/mypage/rateInfo/cs',
              component: () => import('@/views_partner/mypage/pages/rateInfo/cs'),
              meta: {
                text: 'partner.menu.lower.list.title'
              }
            }
            // ,{
            //   name: 'partner_mypage_rateInfo_sm',
            //   path: '/partner/mypage/rateInfo/sm',
            //   component: () => import('@/views_partner/mypage/pages/rateInfo/sm'),
            //   meta: {
            //     text: 'partner.menu.lower.list.title'
            //   }
            // }
          ]
        },
        {
          name: 'partner_lower_create',
          path: '/partner/lower/create',
          component: () => import('@/views_partner/lower/pages/create'),
          meta: {
            text: 'partner.menu.lower.list.title'
          }
        }
      ]
    },
    {
      name: 'partner_lower',
      path: '/partner/lower',
      component: () => import('@/views_partner/lower'),
      meta: {
        text: 'partner.menu.lower.title'
      },
      children: [
        {
          name: 'partner_lower_list',
          path: '/partner/lower/list',
          component: () => import('@/views_partner/lower/pages/list'),
          meta: {
            text: 'partner.menu.lower.list.title'
          }
        },
        {
          name: 'partner_status_list',
          path: '/partner/lower/statusList',
          component: () => import('@/views_partner/lower/pages/statusList'),
          meta: {
            text: 'partner.menu.lower.statusList.title'
          }
        },
        {
          name: 'partner_lower_create',
          path: '/partner/lower/create',
          component: () => import('@/views_partner/lower/pages/create'),
          meta: {
            text: 'partner.menu.lower.list.title'
          }
        }
      ]
    },
    {
      name: 'partner_bet',
      path: '/partner/bet',
      component: () => import('@/views_partner/bet'),
      meta: {
        text: 'partner.menu.bet.title'
      },
      children: [
        {
          name: 'partner_bet_casino',
          path: '/partner/bet/casino',
          component: () => import('@/views_partner/bet/pages/casino'),
          meta: {
            text: 'partner.menu.bet.casino.title'
          }
        },
        {
          name: 'partner_bet_slot',
          path: '/partner/bet/slot',
          component: () => import('@/views_partner/bet/pages/slot'),
          meta: {
            text: 'partner.menu.bet.slot.title'
          }
        }
      ]
    },
    {
      name: 'partner_reports',
      path: '/partner/reports',
      component: () => import('@/views_partner/reports'),
      redirect: { name: 'partner_reports_cash' },
      meta: {
        text: 'partner.menu.reports.title'
      },
      children: [
        {
          name: 'partner_reports_cash',
          path: '/partner/reports/cash',
          component: () => import('@/views_partner/reports/pages/cash'),
          redirect: { name: 'partner_reports_cash_deposit' },
          meta: {
            text: 'partner.menu.reports.cash.title'
          },
          children: [
            {
              name: 'partner_reports_cash_deposit',
              path: '/partner/reports/cash/deposit',
              component: () => import('@/views_partner/reports/pages/cash/deposit'),
              meta: {
                text: 'partner.menu.reports.cash.in.title'
              }
            },
            {
              name: 'partner_reports_cash_withdraw',
              path: '/partner/reports/cash/withdraw',
              component: () => import('@/views_partner/reports/pages/cash/withdraw'),
              meta: {
                text: 'partner.menu.reports.cash.out.title'
              }
            },
            {
              name: 'partner_reports_cash_send',
              path: '/partner/reports/cash/send',
              component: () => import('@/views_partner/reports/pages/cash/send'),
              meta: {
                text: 'partner.menu.reports.cash.send.title'
              }
            }
          ]
        },
        {
          name: 'partner_reports_summary',
          path: '/partner/reports/summary',
          component: () => import('@/views_partner/reports/pages/summary'),
          meta: {
            text: 'partner.menu.reports.summary.title'
          }
        },
        {
          name: 'partner_reports_daily',
          path: '/partner/reports/daily',
          component: () => import('@/views_partner/reports/pages/daily'),
          meta: {
            text: 'partner.menu.reports.daily.title'
          }
        }
      ]
    }
  ]
}
