<template>
  <div class="loginmodal">
    <div class="msgArt">
      <img src="@/assets/img/icon_airplane.svg" />
      <p>신규 메세지가 도착했습니다.<br />메세지 함으로 이동합니다.</p>
      <a @click="goPageByName('messagelist')">{{ $t('front.board.check') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewMessage'
}
</script>

<style scoped>

</style>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
